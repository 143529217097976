export default {
  colors: {
    // accessible color picker: http://colorsafe.co/
    // primary accent is used for 14px navigation text, so needs to skew readable
    // there are basically no accessible orange colors so pick midway between red and brown
    primary: '#C7131F',
    //primary: '#37475a',
    background: '#FFFFFF',
    shape: `#F2F2FA`,
    // JIRA: #172B4D
    // Medium: rgba(41, 41, 41, 1)
    title: `rgba(41, 41, 41, 1)`,
    text: `rgba(41, 41, 41, 1)`,
    components: {
      blockquote: {
        background: `#F5F5F5`,
        text: `#172B4D`,
      },
      code: {
        // colors for inline code blocks
        background: `#F5F5F5`,

        // code div/spans generated by theme (Prism) use nightOwlLight theme
        // nightOwlLight's background is rgb(251, 251, 251, 1)
        // but that provides too little contrast to white backgound
        text: `#172B4D`
      }
    },
  },
};
